@import 'base/reset';
@import 'base/fonts';
@import 'keyframes';

// variables
$color_theme: #FF5C00;
// END variables

body {
  font: 400 16px GothamPro, sans-serif;
  line-height: 1.4;
  color:#fff;
}
button,path,circle,input[submit] {
  transition: .2s;
}
input {
  font: 400 16px GothamPro, sans-serif;
}
button,input[type="submit"] {
  font: 400 14px GothamPro, sans-serif;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
button,input[type="submit"],a {
  &:hover {
    transition: .2s;
  }

}
path,rect {
  transition: .2s;
}
input[type="submit"] {
  -webkit-appearance: none;
}
input,textarea {
  border-radius: 0;
}
// =======



body {
  background: url(../images/bg-body.jpg);
  background-attachment: fixed;
  transition: .3s;
}

.text-center {
  text-align: center;
}

.container {
  width: 90vw;
  margin: 0 auto;
}
.wrapper {
  opacity: 1;
  transition: .4s;
}


.title-container {
    color: #fff;
    margin: 40px 0 30px;
    transition: .3s;
}
.deco-line-circle {
  position: relative;
  display: flex;
  align-items: center;
  .line {
    width: 1px;
    height: calc(100% - 16px);
    background-color: #313131;
    margin: 0;
  }
  .circle {
    width: 16px;
    height: 16px;
    border: 1px solid #313131;
    border-radius: 50%;
  }
}
.our-projects-sectiion {
    position: relative;
    padding-top: 50px;
    .container {
        position: relative;
    }
    .deco-line-circle {
      position: absolute;
      top: 0;
      left: calc(50% - 8px);
      height: 120px;
      flex-direction: column;
    }
    .link-more-wrap {
      text-align: center;
      margin: 60px 0 30px;
      .link-more {
        color: $color_theme;
        font-weight: 500;
        padding: 10px 40px;
        border: 1px solid $color_theme;
        display: inline-block;
      }
    }

    .dots-wrapper {
      bottom: 70px;
    }
}

header.header {
  padding: 10px 5vw;
  justify-content: space-between;
  display: flex;
  align-items: center;
  transition: .2s;
  position: sticky;
  top: 0;
  z-index: 999;
  &.active {
    background-color: #000;
  }
  .logo {
    width: 90px;
    a,img {
      display: block;
    }
  }
  &.home {
    .toggle-menu {
      margin-left: auto;
    }
  }
  .toggle-menu {
    background: transparent;
    border: none;
    width: 18px;
    height: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    span {
      height: 2px;
      width: 100%;
      background-color: #fff;
      border-radius: 3px;
      margin-left: auto;
      &:first-child {
        width: 60%;
      }
    }
  }

  .main-menu-wrap {
    position: absolute;
    top: 50%;
    right: 50%;
    z-index: 10;
    width: 0;
    height: 0;
    opacity: 0;
    padding: 0;
    background: #000 url(../images/text-menu_m.svg) center 13% no-repeat;
    transition: .5s;
    overflow: hidden;
    .main-menu-layer {
      opacity: 0;
      height: 100%;
      transform: translateY(20px);
      transition: .4s;
      &.active {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &.active {
      width: 100%;
      height: 100vh;
      top: 0;
      right: 0;
      opacity: 1;
      padding: 15px;
    }
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .toggle-menu-close {
      padding: 0;
      background: transparent;
      border: none;
    }
    .logo-menu {
      width: 90px;
    }
    .list-menu {
      list-style: none;
      padding: 40px;
      background: radial-gradient(circle at center,rgba(255,255,255,.03) 0,transparent 80%);
      li {
        padding: 15px 0;
        border-bottom: 1px solid #6A6A6A;
        position: relative;
        &:last-child {
          border: none;
        }
        a {
          font-weight: 500;
          font-size: 24px;
          color: #6A6A6A;
          &:after {
            content: '';
            display: block;
            height: 1px;
            width: 0;
            background-color: $color_theme;
            position: absolute;
            top: 50%;
            right: 0;
            transition: .5s;
          }
          &:hover {
            color: #fff;
            &:after {
              width: 40%;
            }
          }
        }
        &.active {
          a {
            color: $color_theme;
          }
        }
      }
    }
    .social-list {
      display: flex;
      justify-content: center;
      column-gap: 16px;
      position: absolute;
      bottom: 30px;
      width: 100%;
      left: 0;
      a {
        width: 24px;
      }
    }
  }
}

.first-window,.first-window-portfolio,.first-window-project,.first-window-about-us,.first-window-contact {
  position: relative;
  height: 90vh;
  width: 100%;
  overflow: hidden;
  padding: 40px;
  transition: .4s;
  .list-slides {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .item-slide {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        
      }
    }
  }
  .slick-slider .slick-list, .slick-slider .slick-track {
    height: 100%;
  }
  .figures-wrap {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    background: rgba(0,0,0,.5);
    transition: .1s;
    .elem-figure {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        opacity: .8;
        animation: 4s linear 0s infinite alternate dreamSpot;
        transition: .2s;
      }
    }
  }
  .lamps {
    cursor: pointer;
    transition: .2s;
    position: absolute;
    top: 0;
    left: -9%;
    z-index: 9;
    width: 200px;
    height: 180px;
    .lamp-1,.lamp-2 {
      position: absolute;
      top: 0;
      z-index: 3;
    }
    .lamp-1 {
      left: 2%;
    }
    .lamp-2 {
      left: 35%;
    }
    &:hover {
      opacity: .8;
    }
  }

  
  
  .social-list {
    display: flex;
    position: absolute;
    bottom: 8px;
    left: 30px;
    column-gap: 16px;
    z-index: 9;
    circle {
      transition: .2s;
    }
    a {
      width: 24px;
      &:hover {
        circle {
          fill: $color_theme;
        }
        path {
          fill: #fff;
        }
      }
    }
  }

  .logo {
    position: absolute;
    top: calc(50% - 130px);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 170px;
    z-index: 10;
  }
}
.dots-wrapper {
  position: absolute;
  z-index: 9;
  bottom: 20px;
  left: 0;
  list-style: none;
  display: flex;
  column-gap: 17px;
  width: 100%;
  justify-content: center;
  button {
    padding: 0;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: #6a6a6a;
    transition: .3s;
    &:hover {
      border-color: $color_theme;
    }
  }
  li {
    &.active {
      button {
        background-color: $color_theme;
        width: 16px;
      }
    }
  }
}

.first-window {
  .dots-wrapper {
    bottom: 22%;
    z-index: 99;
  }
}

.first-window-portfolio {
  .figures-wrap {
    background: transparent;
    .elem-figure {
      img {
        opacity: 1;
      }
    }
  }
  .list-slides {
    .slick-list,.slick-track,.slick-slide {
      height: 100%;
    }
    .item-slide {
      img {
        object-fit: fill;
      }
      
    }
  }
  .slick-dots {
    bottom: 30px;
  }
  .list-slides-elems {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 8;
    top: 0;
    left: 0;
    
    .slick-list, .slick-track {
      height: 100%;
    }
    .item-slide {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }
    .slide-elem-1,.slide-elem-2,.slide-elem-3 {
      transition: .7s;
    }
    .slide-elem-1 {
      transform: translateX(-50%);
      width: 250px;
      margin-left: -80px;
      margin-bottom: 50px;
    }
    .slide-elem-2 {
      margin-left: 17vw;
      transform: translateX(50%);
      width: 300px;
    }
    .slide-elem-3 {
      position: absolute;
      top: 0;
      right: 6vw;
      transform: translateY(-10%);
      width: 90px;
    }
    .slick-current {
      .slide-elem-1 {
        transform: translateX(0);
      }
      .slide-elem-2 {
        transform: translateX(0);
      }
      .slide-elem-3 {
        transform: translateY(0);
      }
      .text-portfolio-wrap {
        img {
          transform: translateY(0);
        }
        .text,.subtext {
          transform: translateY(0);
        }
      }
    }
  }
  .text-portfolio-wrap {
    img {
      transform: translateY(-40px);
      transition: .4s;
    }
    .text,.subtext {
      transform: translateY(40px);
      transition: .4s;
    }
  }
}
.text-portfolio-wrap {
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
  z-index: 3;
  text-align: justify;
  padding: 0 50px;
  .text {
    color: #F0F0F0;
    margin: 15px 0;
  }
  .subtext {
    font-size: 12px;
    line-height: 1.8;
    color: #6A6A6A;
  }
}

.first-window-about-us {
  height: auto;
  min-height: 90vh;
  .content-about-us {
    padding: 30px 0;
    display: grid;
    grid-template-columns: 35% 65%;
    column-gap: 15px;
    background: radial-gradient(circle at center, rgba(255,255,255,.03) 0, transparent 43%);
    .img-wrap {
      width: calc(100% + 10vw);
      margin-left: -10vh;
    }
    .text-about-us-wrap {
      padding-right: 10px;
    }
    .main-text {
      font-size: 12px;
      line-height: 1.8;
      margin-top: 15px;
    }
  }
  .line {
    margin-top: 25px;
    height: 1px;
    background-color: #6a6a6a;
    transition: 2s;
    width: 100%;
  }
  .advant-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    margin-top: 10%;
    .title {
      font-size: 12px;
      font-weight: 700;
      color: #f0f0f0;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .text {
      color: #6A6A6A;
      font-size: 10px;
      line-height: 1.8;
    }
  }
}

.lets-meet-section {
  margin: 20px 0;
  position: relative;
  border-bottom: 1px solid #313131;
  .image-team {
    position: relative;
    margin-top: -10px;
    text-align: right;
    img {
      display: block;
      margin-left: auto;
      width: 100%;
    }
  }
}

.first-window-contact {
  padding: 20px 30px;
  background: radial-gradient(circle at center,rgba(255,255,255,.03) 0,transparent 43%);
  height: auto;
  .lamps {
    left: 46%;
  }
  .contact-text {
    color: #f0f0f0;
    margin: 30px 0 40px;
    font-size: 12px;
    line-height: 1.8;
  }
}

.our-production-section {
  position: relative;
  padding: 20px 0 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid #313131;
  .elem-lamp {
    position: absolute;
    right: 5%;
    top: -20px;
    width: 75px;
  }
  .row {
    display: grid;
    grid-template-columns: 25% 75%;
    align-items: flex-end;
  }
  .title-container {
    margin-bottom: 15px;
  }
  .subtext-col {
    position: relative;
    z-index: 2;
    margin-top: 30px;
    padding-top: 25px;
    column-count: 2;
    .title {
      font-size: 12px;
      font-weight: 700;
      position: absolute;
      left: 0;
      top: 0;
    }
    p {
      font-size: 10px;
      color: #6A6A6A;
      margin-bottom: 20px;
      line-height: 1.6;
    }
  }
  .tree-col {
    position: relative;
    z-index: 1;
    width: 230%;
    img {
      position: relative;
      width: 150%;
      max-width: none;
      max-height: none;
      bottom: 0;
      left: -92%;
    }
  }
  .text-col {
    position: relative;
    z-index: 2;
    .text {
      color: #f0f0f0;
      font-size: 12px;
      line-height: 1.6;
    }
  }
}

.list-gallery {
    margin-left: -8px;
    margin-right: -5vw;
    .slick-slide {
        padding: 0 4px;
    }
    .item-gallery {
        position: relative;
        height: 80vh;
        .name-project {
            color: #fff;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 3px;
            transition: 1s;
            display: inline-block;
            position: absolute;
            left: 20px;
            bottom: 15px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.slick-dots {
  bottom: -50px;
  z-index: 9;
  li {
    width: 10px;
    button {
      padding: 0;
      width: 4px;
      height: 4px;
      border-radius: 5px;
      background-color: #6A6A6A;
      transition: .3s;
      &:before {
        content: none;
      }
    }
    &.slick-active {
      button {
        width: 16px;
        background-color: $color_theme;
      }
    }
  }
}

.about-us-section {
  padding: 60px 0 30px;
  position: relative;
  .lamp-top {
    position: absolute;
    top: 0;
    left: -15px;
    width: 100px;
  }
  .text-wrapper-top,.text-wrapper-bottom {
    padding: 0 20px;
  }
  .link {
    display: inline-block;
    padding: 4px 0;
    font-weight: 700;
    color: $color_theme;
    font-size: 14px;
    margin-top: 15px;
    border-bottom: 2px solid $color_theme;
    letter-spacing: 1px;
  }
  .top-text,.bottom-text {
    padding: 30px;
    color: #f0f0f0;
    font-size: 12px;
    transition: 1s;
    opacity: 0;
    .title-block {
      font-weight: 700;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .text {
      line-height: 1.9;
    }
  }
  .top-text {
    border: 1px solid #313131;
    padding-bottom: 120px;
    margin-bottom: -110px;
    transform: translateY(-60px);
  }
  .text-wrapper-bottom {
    margin-top: -60px;
    position: relative;
    .deco-image {
      width: 100%;
      height: 60px;
      display: block;
      position: relative;
      z-index: 9;
    }
  }
  .bottom-text {
    border: 1px solid #313131;
    border-top: none;
    position: relative;
    padding: 10px 30px 30px;
    transform: translateY(60px);
  }

  .center-images-wrap {
    height: 250px;
    width: calc(100% + 10vw);
    margin-left: -5vw;
    background: url(../images/about-us-floor.png) center center no-repeat;
    background-size: cover;
    position: relative;
    .elem-flower,.elem-owner,.elem-lamp {
      opacity: 0;
      transform: scale(0);
      transition: 1s;
    }
    .elem-flower {
      position: absolute;
      left: 6px;
      width: 100px;
    }
    .elem-owner {
      position: absolute;
      top: -20px;
      right: 70px;
      width: 260px;
      z-index: 9;
    }
    .elem-lamp {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100px;
    }
  }
}


.statistics-section {
  display: flex;
  flex-wrap: wrap;
  .title-container {
    padding-bottom: 20px;
    border-bottom: 1px solid #313131;
    margin-top: 20px;
    margin-bottom: 0;
    width: 100%;
    .subtitle {
      color: #6A6A6A;
      font-size: 10px;
      font-weight: 700;
      margin-top: 10px;
    }
  }
  .item-statistic {
    width: 50%;
    text-align: center;
    font-weight: 700;
    padding: 20px 0;
    border-bottom: 1px solid #313131;
    position: relative;
    &:nth-child(even) {
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 10px;
        width: 1px;
        background-color: #313131;
        height: calc(100% - 20px);
      }
    }
    .viz {
      opacity: 1;
      transition: 1s;
    }
    .count {
      font-size: 40px;
    }
    .value {
      font-size: 10px;
    }
  }
}

.advantages-section {
  padding: 20px 0;
  overflow: hidden;
  .advantage-desc {
    padding: 20px 0;
    font-size: 16px;
    line-height: 2;
  }
  .center-image {
    position: relative;
    height: 90vh;
    .main-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .deco {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .list-items-advantage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 30px;
    flex-wrap: wrap;
    .item-advantage {
      position: relative;
      .count {
        margin-bottom: 10px;
      }
      .title {
        font-size: 12px;
        font-weight: 700;
        color: #f0f0f0;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      .text {
        color: #6A6A6A;
        font-size: 12px;
        line-height: 1.8;
      }
    }
  }
}



.slider-reviews-wrapper {
  
  width: 100%;
  position: relative;
  .slider-reviews {
    width: 100%;
  }
  .item-slider-wrap {
    text-align: center;
    padding: 9px;
    border: 1px solid #323232;
    .user-thumb {
      width: 108px;
      height: 108px;
      border-radius: 50%;
      padding: 3px;
      overflow: hidden;
      border: 1px solid #323232;
      margin: 0 auto 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title {
      color: #6A6A6A;
      margin-bottom: 10px;
      font-weight: 700;
    }
    .name {
      color: #F0F0F0;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .text {
      line-height: 2;
      color: #6A6A6A;
      font-size: 10px;
    }
  }
  .item-slider {
    padding: 30px 20px 50px;
    border: 1px solid #323232;
    position: relative;
  }
  .quote-start, .quote-end {
    position: absolute;
  }
  .quote-start {
    top: 15px;
    left: 15px;
  }
  .quote-end {
    bottom: 15px;
    right: 15px;
  }

  .slick-next, .slick-prev {
    z-index: 2;
    &:before {
      content: none;
    }
    &:hover {
      path {
        stroke: $color_theme;
      }
    }
  }
  .slick-next {
    right: -15px;
  }
  .slick-prev {
    left: -7px;
  }
  .slick-slide {
    padding: 0 15px;
  }
}

.contact-section {
  opacity: 0;
  transform: translateY(150px);
  transition: 1.5s;
  .contact-form-wrapper {
    padding-top: 30px;
  }
  .title-container-wrap {
    border-bottom: 1px solid #6A6A6A;
  }
  .title-contact-container {
    margin: 40px 0 20px;
  }
  .our-team {
    margin-bottom: 20px;
  }

  .content-wrap {
    display: grid;
    padding: 20px 0;
    .text {
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 2;
      margin-bottom: 20px;
      a {
        color: $color_theme;
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .btn-submit-wrap {
    margin-top: 70px;
  }
  
}
.btn-submit {
  display: inline-block;
  border: none;
  border-bottom: 2px solid $color_theme;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 5px;
  color: $color_theme;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background-color: $color_theme;
    transition: .4s;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:after {
      height: 100%;
    }
  }
  &.loading {
    border-color: transparent;
    animation: 1s linear infinite btnTextAnimatiion;
    &:hover {
      &:after {
        height: 2px;
      }
    }
    &:after {
      height: 2px;
      animation: 1s linear infinite btnLineAnimatiion;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &[disabled] {
    cursor: default;
  }
  &.sent {
    border-color: #eee;
    color: #eee;
    &:after {
      content: none;
    }
  }
}

.text-right {
  text-align: right !important;
}


.input-style {
  margin-bottom: 20px;
  position: relative;
  input:not([type='submit']),textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid #3E3E3E;
    padding: 15px 0;
    background: transparent;
    color: #fff;
    font-weight: 700;
    position: relative;
    z-index: 2;
    transition: .3s;
    &:focus {
      border-color: $color_theme;
      + span.label {
        font-size: 12px;
        top: -10px;
        padding-bottom: 50px;
        color: $color_theme;
      }
    }
  }
  .file-attachment {
    position: absolute;
    cursor: pointer;
    transition: .2s;
    top: -15px;
    right: 0;
    &:hover {
      opacity: .7;
    }
    input {
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
  span.label {
    color: #727272;
    transition: .5s;
    display: block;
    padding-bottom: 40px;
    position: absolute;
    left: 0;
    top: -7px;
  }
}

.projects-sliders {
  padding: 60px 0 30px;
  position: relative;
  .projects-sliders-list {
    height: 75vh;
  }
  .item-slide {
    height: 100%;
  }
  .slick-list,.slick-track {
    height: 100%;
  }
  .slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.statistic-slider {
  .title-container {
    img {
      display: inline-block;
    }
  }
  .statistics-section {
    .item-statistic {
      .count {
        font-size: 24px;
      }
    }
  }
}

.list-files {
  padding-top: 40px;
  .item-file {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    padding: 3px 0;
    .ico {
      width: 25px;
    }
  }
}

.first-window-project {
  .switch-theme {
    left: auto;
    right: 20%;
  }
  .figures-wrap {
    background: transparent;
    .elem-figure {
      img {
        opacity: 1;
      }
    }
  }
}

.text-project-wrap {
  position: absolute;
  top: 25%;
  left: 0;
  width: 100%;
  z-index: 3;
  text-align: justify;
  transition: .3s;
  .text-wrap {
    display: flex;
    justify-content: flex-end;
    padding-top: 80px;
    position: relative;
    .line {
      position: absolute;
      top: 40px;
      left: 0;
      height: 1px;
      background-color: #6a6a6a;
      transition: 2s;
      width: 0;
    }
  }
  .text {
    width: 60%;
    color: #F0F0F0;
    margin: 15px 0;
    font-size: 12px;
    line-height: 2;
  }
}

.portfolio-section {
  padding: 80px 0 30px;
  position: relative;
  .deco-line-circle {
    position: absolute;
    top: 0;
    left: calc(50% - 8px);
    height: 120px;
    flex-direction: column;
  }
  .slick-slide {
    padding: 0 4px;;
  }
  .link-more-wrap {
    text-align: right;
    margin-top: -15px;
    position: relative;
    z-index: 9;
    .link-more {
      color: $color_theme;
      display: inline-block;
      padding: 0 5px;
      border-bottom: 2px solid $color_theme;
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      position: relative;
      &:after {
        content: '';
        display: block;
        height: 0;
        background-color: #ff5c00;
        transition: .3s;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;
      }
      &:hover {
        color: #fff;
        &:after {
          height: 100%;
        }
      }
      .ico {
        vertical-align: middle;
      }
    }
  }
  .slick-dots {
    text-align: left;
    margin-bottom: 0;
  }
}


.list-portfolio {
  margin-left: -8px;
  margin-right: -5vw;
  .portfolio-item {
    border: 1px solid #323232;
    position: relative;
    overflow: hidden;
    transition: .2s;
    height: 75vh;
    &:hover {
      border-color: $color_theme;
      .thumb {
        transform: scale(1.1);
      }
      .elem-slice {
        height: 160px;
      }
    }
    .thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 4s;
    }
    .elem-slice {
      position: absolute;
      left: 0;
      bottom: 0;
      transition: 1s;
      height: 150px;
      min-width: 100%;
    }
    .info-project {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      padding: 15px 25px;
    }
    .name-project {
      font-size: 24px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 7px;
    }
    .link-more {
      color: $color_theme;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 700;
      &:hover {
        color: #fff;
      }
    }
  }
}
input {
  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #171717;
    -webkit-text-fill-color: #fff;
  }
}
.map-container {
  height: 600px;
  background: #303030;
  position: relative;
  .contact-list-wrap {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 40px;
  }
  .slice-deco {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 2;
    transition: 1s;
  }
  .address-block {
    grid-column: 1/3;
    margin-top: 8px;
  }
}
.contact-list {
  display: grid;
  column-gap: 8px;
  grid-template-columns: 1fr 1fr;
  .item-contact {
    padding: 10px;
    background-color: #171717;
    color: #fff;
    .title {
      font-weight: 700;
      font-size: 12px;
      margin-bottom: 10px;
      &:after {
        content: '';
        width: 50%;
        height: 1px;
        display: inline-block;
        background-color: #fff;
        margin-left: 7px;
        vertical-align: middle;
      }
    }
    p {
      margin: 5px 0;
      color: #D3D3D3;
      font-size: 12px;
    }
    a {
      color: #D3D3D3;
      &:hover {
        color: $color_theme;
      }
    }
  }
}
#custom-map {
  position: absolute;
  width: 100%;
  height: 100%;
}
div#custom-map a,
div#custom-map button,
div.gm-bundled-control div.gmnoprint, div.gm-svpc {
  display: none !important;
}

.line {
  margin: 15px 0;
  height: 1px;
  background-color: #6a6a6a;
  transition: 2s;
  width: 100%;
}

.reviews-section {
  position: relative;
  .dots-wrapper {
    bottom: -25px;
  }
}

body.light {
  background-image: url(../images/bg-body-light.jpg);
  .title-container {
    filter: invert(1);
  }
  .switch-theme {
    background: radial-gradient(circle 87px at 45% 54%,#ffec97,transparent);
    animation: 2s linear 0s infinite alternate switchAnimatiion;
  }

  header.header {
    &:not(.active) {
      .toggle-menu {
        span {
          background-color: #111;
        }
      }
      .logo {
        filter: invert(1);
      }
    }
  }

  .about-us-section {
    .top-text {
      .title-block,.text {
        filter: invert(1);
      }
    }
    .text-wrapper-bottom {
      .text,.deco-image,.title-block {
        filter: invert(1);
      }
    }
  }
  .statistics-section {
    .item-statistic {
      .count,.value {
        filter: invert(1);
      }
    }
  }
  .advantages-section {
    .advantage-desc {
      filter: invert(1);
    }
    .list-items-advantage .item-advantage .title {
      filter: invert(1);
    }
  }
  .slider-reviews-wrapper .slick-next, .slider-reviews-wrapper .slick-prev {
    filter: invert(1);
  }
  .contact-section .content-wrap .text {
    color: #1A1A1A;
  }
  .input-style input:not([type=submit]), .input-style textarea {
    color: #171717;
  }
  .slider-reviews-wrapper .item-slider-wrap .name {
    color: #171717;
  }
  .map-container .slice-deco,.advantages-section .deco, .advantages-section .deco-bottom-right, .advantages-section .deco-top-left {
    filter: invert(1);
  }
  .list-portfolio {
    .portfolio-item {
      border: none;
      .name-project {
        color: #171717;
      }
      .elem-slice {
        filter: invert(1);
      }
    }
  }
  .portfolio-section {
    .link-more-wrap .link-more {
      path {
        stroke: $color_theme;
      }
      &:hover {
        path {
          stroke: #fff;
        }
      }
    }
  }
  .text-project-wrap {
    left: 15px;
    width: calc(100% - 30px);
  }
  .slider-reviews-wrapper {
    .dots .slick-dots li {
      border-color: #171717;
    }
    .item-slider-wrap {
      .text {
        color: #171717;
      }
    }
  }
  .first-window-contact {
    .text-contact-wrap {
      img,.contact-text {
        filter: invert(1);
      }
    }
  }
  .first-window-about-us {
    .logo-header {
      filter: invert(1);
    }
    
    .content-about-us {
      .img-wrap {
        img {
          filter: invert(1);
        }
      }
    }
    .text-about-us-wrap {
      img {
        filter: invert(1);
      }
    }
    .text-wrap {
      color: #171717;
    }
    .advant-list {
      .title {
        color: #171717;
      }
    }
  }
  .first-window-about-us,.first-window-contact {
    .social-list {
      circle {
        fill: $color_theme;
      }
      path {
        fill: #fff;
      }
    }
  }
  
  .our-production-section {
    .subtext-col .title,.text-col .text {
      color: #171717;
    }
  }
  .reviews-section .text-content .text {
    color: #171717;
    a {
      color: $color_theme;
    }
  }
  .contact-section {
    .title-contact-container {
      filter: invert(1);
    }
  }
  footer {
    .logo {
      filter: invert(1);
    }
    .social-list {
      a {
        color: #171717;
      }
    }
  }
}

.main-title-project {
  font: normal 48px NewYork;
  padding-left: 15px;
  width: 70%;
 }

footer.footer {
  text-align: center;
  .logo {
    padding: 20px 0 10px;
  }
  .copy {
    color: #6A6A6A;
    font-size: 10px;
    padding-bottom: 20px;
  }
  .social-list {
    a {
      color: #F0F0F0;
      font-size: 12px;
      margin: 0 7px;
      &:hover {
        color: $color_theme;
      }
    }
  }
}